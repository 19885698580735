import React from 'react'
import { graphql, Link } from 'gatsby'

import WorkContainer from '../../components/WorkContainer'
import Layout from '../../components/Layout'
import { ProjectTitle, EnglishOnly } from '../../components/Text'
import Metadata from '../../components/Metadata'
import CustomerLogo from '../../components/CustomerLogo'
import ProjectImage from '../../components/ProjectImage'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

export default function CherryKit({ data }) {
  const { t } = useTranslation()

  const project = data.projectsJson

  return (
    <Layout>
      <Metadata title={t(project.title)} isArticle />
      <WorkContainer>
        <CustomerLogo project={project} />
        <ProjectTitle title={t(project.title)} />
        <ProjectImage data={project.gallery.main} top />
        <h2>{t('project.section.challenge')}</h2>
        <p>{t('cherrykit.challenge2')}</p>
        <p>{t('cherrykit.challenge3')}</p>
        <blockquote>{t('cherrykit.challengeQuot1')}</blockquote>
        <p>{t('cherrykit.challenge4')}</p>
        <blockquote>{t('cherrykit.challengeQuot2')}</blockquote>
        <p>{t('cherrykit.challengeReq.title')}</p>
        <ol>
          <li>{t('cherrykit.challengeReq.r1')}</li>
          <li>{t('cherrykit.challengeReq.r2')}</li>
          <li>{t('cherrykit.challengeReq.r3')}</li>
        </ol>

        <h2>{t('project.section.approach')}</h2>

        <p>
          <Trans i18nKey="cherrykit.approach1-html" components={[<em />]}>
            We solve the <em>no installation</em> requirement by writing this
            application for the web.
          </Trans>
        </p>
        <p>
          <Trans i18nKey="cherrykit.approach2-html" components={[<em />]}>
            To address the <em>always printable</em> requirement, we use a
            mathematical concept called "signed distance functions" (SDFs). SDFs
            let us design objects using mathematical functions.
          </Trans>
        </p>
        {/* <p>
          {t('cherrykit.approach3-1')}
          <pre>
            <code>
              x<sup>2</sup> + y<sup>2</sup> + z<sup>2</sup> - r<sup>2</sup> = 0
            </code>
          </pre>
          <Trans i18nKey="cherrykit.approach3-2-html" components={[<em />]}>
            is the actual formula that designs a sphere in our software. And
            there are infinite ways to combine simple shapes into complicated
            parts using so-called <em>Boolean operations</em>.
          </Trans>
        </p> */}
        <p>
          <Trans
            i18nKey="cherrykit.approachList.title-html"
            components={[<em />]}
          >
            Intermediate users utilize market features from our other project{' '}
            <Link to="../ideacomposition">Idea Composition</Link> to allow
            people to re-use improvements from others.
          </Trans>
        </p>
        <ul>
          <li>{t('cherrykit.approachList.a1')}</li>
          <li>{t('cherrykit.approachList.a2')}</li>
          <li>
            <Trans
              i18nKey="cherrykit.approachList.a3-html"
              components={[<Link to="../ideacomposition" />]}
            >
              Intermediate users utilize market features from our other project{' '}
              <Link to="../ideacomposition">Idea Composition</Link> to allow
              people to re-use improvements from others.
            </Trans>
          </li>
        </ul>

        <h2>{t('project.section.result')}</h2>
        <p>{t('cherrykit.result1')}</p>
        <blockquote>{t('cherrykit.resultQuot1')}</blockquote>

        <p>
          <Trans i18nKey="cherrykit.result2-html" components={[<em />]}>
            It works using a mechanism that is called <em>parametric design</em>
            : Experts can write mathematical formulas to design shapes from
            scratch. We also added tools to draw organic-looking shapes that go
            beyond the capability of existing tools like OpenSCAD.
          </Trans>
        </p>
        <p>{t('cherrykit.result3')}</p>
        <blockquote>{t('cherrykit.resultQuot2')}</blockquote>
        <p>{t('cherrykit.result4')}</p>
        <p>
          <Trans
            i18nKey="cherrykit.result5-html"
            components={[
              <a
                href="http://www.cherrykit.com"
                target="_blank"
                rel="noreferrer"
              />,
            ]}
          >
            The website is realized as a multi-language site (English and
            German). Visit it at{' '}
            <a href="http://www.cherrykit.com" target="_blank" rel="noreferrer">
              cherrykit.com
            </a>{' '}
            and print some Duplo/Brio-compatible connectors for your nieces and
            nephews. They work really well!
          </Trans>
        </p>
      </WorkContainer>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    projectsJson(name: { eq: "cherrykit" }) {
      name
      title
      customer
      customerLogo {
        childImageSharp {
          gatsbyImageData
        }
      }
      tags
      link
      gallery {
        main {
          alt
          src {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        cherrykit_banner {
          alt
          src {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
